#Banner {
	/*background-image: url('./website-background-crop-skinny.svg');*/
	/*background-image: url('./splash-watercolor.svg'); */
	/*background-image: url('./colorful-art-crop.jpg'); */
	background-image: url('./waves.jpg'); 
  	background-color: #ffffff; 
 	/*height: 15vh;  */
  	/*background-position: 50% 65%; */
  	/*background-position: 50% 37%; */
  	background-position: 50% 40%;
  	background-repeat: no-repeat; 
  	background-size: 100%, 100%; 
  	color: #ffffff;
}